














































.no-active:before {
  opacity: 0 !important;
}
.v-card__title {
  word-break: normal !important;
}
.theme--light.v-icon {
  color: #333333 !important;
}

.custom-link {
  color: inherit !important;
  text-decoration: none;
}

.custom-container {
  height: 100%;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
}

.v-card__text {
  color: #333333 !important;
  font-size: 16px !important;
  line-height: 1.4 !important;
}
